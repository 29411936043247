export default {
  data(){
      return{
        orderNo:'',
        amount:''
      }
  },
  created(){
    let data = this.$route.query.orderNo.split('?')
    this.amount = data[1].split('=')[1]
    this.orderNo = data[0]
  },
  methods:{
      
  }
}